<template>
  <v-app-bar
    color="background"
    scroll-behavior="elevate"
    height="80"
    class="d-flex"
  >
    <div
      class="mx-auto d-flex align-center justify-space-between"
      style="max-width: 1200px; width: 90vw"
    >
      <div class="d-flex align-center" style="gap: 15px">
        <v-img
          @click="$router.push('/')"
          style="width: 165px; cursor: pointer"
          src="@/assets/logo-landscape.svg"
        ></v-img>
      </div>
      <div
        class="d-flex"
        v-if="!this.$vuetify.display.smAndDown"
        style="gap: 3px"
      >
        <v-btn
          @click="$router.push('/')"
          :variant="$route.path === '/' ? 'tonal' : 'text'"
          >Home</v-btn
        >

        <v-menu open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn
              :variant="$route.path === '/overons' ? 'tonal' : 'text'"
              v-bind="props"
              @click="$router.push('/overons')"
            >
              Over ons
            </v-btn>
          </template>
          <v-list>
            <div v-for="(item, index) in overOns" :key="index">
              <v-list-item :value="index" @click="$router.push(item.link)">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>

        <v-menu open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn
              @click="$router.push('/organisatie')"
              :variant="$route.path === '/organisatie' ? 'tonal' : 'text'"
              v-bind="props"
            >
              Organisatie
            </v-btn>
          </template>
          <v-list>
            <div v-for="(item, index) in organisatie" :key="index">
              <v-list-item :value="index" @click="$router.push(item.link)">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
        <v-btn
          @click="$router.push('/nieuws')"
          :variant="$route.path === '/nieuws' ? 'tonal' : 'text'"
          >Nieuws</v-btn
        >
        <v-btn
          @click="$router.push('/contact')"
          :variant="$route.path === '/contact' ? 'tonal' : 'text'"
          >Contact</v-btn
        >
        <v-btn
          @click="$router.push('/steunons')"
          class="ml-4"
          variant="elevated"
          color="primary"
          >Steun ons</v-btn
        >
        <!-- <v-btn class="ml-2" variant="outlined">Aanvragen</v-btn> -->
      </div>
      <div v-else>
        <v-btn
          @click="$store.commit('setDrawer', !$store.state.drawer)"
          icon="mdi-menu"
        ></v-btn>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      overOns: [
        {
          text: "Historie",
          link: "/overons#historie",
        },
        {
          text: "Missie",
          link: "/overons#missie",
        },
        {
          text: "Visie",
          link: "/overons#visie",
        },
        {
          text: "Werkwijze",
          link: "/overons#werkwijze",
        },
      ],
      organisatie: [
        {
          text: "Team",
          link: "/organisatie#team",
        },
        {
          text: "Beleid",
          link: "/organisatie#beleid",
        },
        {
          text: "Sponsoren",
          link: "/organisatie#sponsoren",
        },
      ],
    };
  },
};
</script>
