<template>
  <div class="bg-secondary" id="sponsoren">
    <div class="section" style="margin: 0 auto">
      <div class="sponsors">
        <div class="left">
          <p class="text-primary mb-2"><b>Sponsoren</b></p>
          <h2>Met enorm veel dank aan onze sponsoren</h2>
          <p class="mt-4" style="font-size: 14pt">
            Sponsoring vormt de belangrijkste bron van inkomsten. Financiële
            bijdragen worden alleen gebruikt voor zogeheten externe kosten
            (kosten van derden), dus niet voor de interne organisatie. Ziet u
            het belang van WeShare4Kids en wilt u een (financiële) bijdrage
            doen, dan heel graag! Daar zijn wij en vooral de gezinnen waarvoor
            wij ons best doen, ontzettend blij mee.
          </p>
          <v-btn
            @click="$router.push('/steunons')"
            color="primary"
            variant="elevated"
            class="mt-6"
            >Wil jij ook sponsor worden?</v-btn
          >
        </div>
        <div class="right">
          <div v-for="(s, index) in sponsors" :key="index">
            <v-card
              height="250"
              flat
              @click="openUrl(s.url)"
              color="background"
              class="pa-6 d-flex justify-center align-center"
            >
              <v-img width="100%" contain :src="getImgUrl(s.src)"></v-img>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sponsors: [
        {
          url: "https://www.rabobank.nl/",
          src: "rabobank.webp",
        },
        {
          url: "https://www.huijbregtsnotarissen.com/",
          src: "huijbregts.svg",
        },
        {
          url: "https://www.holla.nl/",
          src: "holla.jpg",
        },
        {
          url: "https://www.bdo.nl/",
          src: "BDO.svg",
        },
      ],
    };
  },
  methods: {
    getImgUrl(src) {
      return require("@/assets/sponsors/" + src);
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.sponsors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  padding: 5vh 0;
  color: white;

  .left {
    padding-right: 10vw;
    h2 {
      font-size: 22pt;
      line-height: 26pt;
    }
  }

  .right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr 1fr;
    gap: 20px;
    align-items: center;
  }
}
@media screen and (max-width: 700px) {
  .sponsors {
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 5vh 0;
    color: white;

    .left {
      padding-right: 10vw;
      h2 {
        font-size: 22pt;
        line-height: 26pt;
      }
    }

    .right {
      display: grid;
      // grid-template-columns: 1fr;
      grid-auto-rows: 1fr;
      gap: 20px;
    }
  }
}
</style>
