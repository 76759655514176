<template>
  <div>
    <div class="section text-center" id="team">
      <!-- <p class="text-primary"><b>Lorem ipsum</b></p> -->
      <h2 class="mb-10" style="font-size: 22pt">Team</h2>
      <div class="team">
        <v-card max-width="400" class="d-flex flex-column align-center pa-8">
          <v-img
            max-height="100"
            width="100"
            cover
            style="border-radius: 12px"
            :src="require('@/assets/team/gerard.jpeg')"
          ></v-img>
          <h3 class="mt-4 mb-0">Gerard van Kaathoven</h3>
          <p class="mt-3">Voorzitter</p>
        </v-card>

        <v-card max-width="400" class="d-flex flex-column align-center pa-8">
          <v-img
            max-height="100"
            width="100"
            cover
            style="border-radius: 12px"
            :src="require('@/assets/team/jos.jpeg')"
          ></v-img>
          <h3 class="mt-4 mb-0">Jos van Haaren</h3>
          <p class="mt-3">Secretaris - Penningmeester</p>
        </v-card>

        <v-card max-width="400" class="d-flex flex-column align-center pa-8">
          <v-img
            max-height="100"
            width="100"
            cover
            style="border-radius: 12px"
            :src="require('@/assets/team/jan.jpeg')"
          ></v-img>
          <h3 class="mt-4 mb-0">Jan Aussems</h3>
          <p class="mt-3">Lid</p>
        </v-card>

        <v-card max-width="400" class="d-flex flex-column align-center pa-8">
          <v-img
            max-height="100"
            width="100"
            cover
            style="border-radius: 12px"
            :src="require('@/assets/team/antoinette.png')"
          ></v-img>
          <h3 class="mt-4 mb-0">Antoinette Janssen</h3>
        </v-card>
      </div>
    </div>

    <div class="section" id="beleid">
      <div class="d-flex justify-center">
        <h2 class="mb-10" style="font-size: 22pt">Beleid</h2>
      </div>
      <div class="quick">
        <v-card
          class="pa-8 elevation-2 text-center align-center d-flex flex-column justify-center"
        >
          <!-- <v-img class="my-4" width="65" src="@/assets/logo.svg"></v-img> -->
          <h2>Hoofdlijnen beleidsplan</h2>
          <p class="mt-2 mb-6">
            Ontdek meer over onze aanpak en hoe wij gezinnen ondersteunen.
          </p>
          <v-btn variant="outlined" @click="openPlan">Bekijk het plan</v-btn>
        </v-card>
        <v-card
          class="pa-8 elevation-2 align-center text-center d-flex flex-column justify-center"
        >
          <!-- <v-img class="my-4" width="65" src="@/assets/logo.svg"></v-img> -->
          <h2>Beloningsbeleid</h2>
          <p class="mt-2 mb-6">
            De leden van het Bestuur genieten geen beloning voor hun
            werkzaamheden. Zij hebben wel recht op een niet bovenmatige
            vergoeding van de door hen in de uitoefening van hun functie
            gemaakte kosten.
          </p>
        </v-card>
        <v-card
          class="pa-8 elevation-2 align-center text-center d-flex flex-column justify-center"
        >
          <!-- <v-img class="my-4" width="65" src="@/assets/logo.svg"></v-img> -->
          <h2>Uitgeoefende activiteiten</h2>
          <p class="mt-2 mb-6">Er zijn nog geen activiteiten uitgevoerd.</p>
        </v-card>
        <v-card
          class="pa-8 elevation-2 align-center text-center d-flex flex-column justify-center"
        >
          <!-- <v-img class="my-4" width="65" src="@/assets/logo.svg"></v-img> -->
          <h2>Financiële verantwoording</h2>
          <p class="mt-2 mb-6">
            Er zijn nog geen financiële gegevens beschikbaar omdat de stichting
            WeShare4Kids in 2024 is opgericht.
          </p>
        </v-card>
      </div>
    </div>

    <v-img src="@/assets/bar-secondary.svg"></v-img>

    <SponsorSection />
  </div>
</template>

<script>
import SponsorSection from "@/components/SponsorSection.vue";
import { useMeta } from "vue-meta";

export default {
  components: {
    SponsorSection,
  },
  setup() {
    useMeta({
      title: "Organisatie",
    });
  },
  methods: {
    openPlan() {
      let url = "/Beleidsplan.pdf";
      window.open(url, "_blank"); //to open in new tab
    },
  },
};
</script>

<style lang="scss" scoped>
.quick {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 25px;
}

.team {
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: 700px) {
  .team {
    grid-template-columns: 1fr;
  }
  .quick {
    grid-template-columns: 1fr;
  }
}
</style>
