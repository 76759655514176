<template>
  <div v-if="nieuws.length > 0">
    <div class="section text-center d-flex flex-column align-center pb-8">
      <b
        ><p>{{ nieuws[0].location + " " + getDate(nieuws[0].timestamp) }}</p></b
      >
      <h1 class="my-6" style="max-width: 700px; font-size: 30pt">
        {{ nieuws[0].title }}
      </h1>
      <p style="font-size: 14pt; max-width: 450px">
        {{ nieuws[0].subtitle }}
      </p>
      <div class="d-flex my-8" style="gap: 10px">
        <v-btn
          variant="outlined"
          @click="$router.push(`/nieuws/${nieuws[0].uid}`)"
          >Lees verder</v-btn
        >
        <v-btn
          variant="text"
          append-icon="mdi-arrow-down"
          @click="$router.push('/nieuws#alles')"
          >Bekijk alle artikelen</v-btn
        >
      </div>
      <v-img
        height="30vh"
        width="100%"
        cover
        rounded=""
        class="elevation-4 image"
        :src="nieuws[0].header_image"
      ></v-img>
    </div>

    <div class="d-flex justify-center" id="alles">
      <v-icon size="100" color="tertiary" style="opacity: 0.5"
        >mdi-chevron-down</v-icon
      >
    </div>

    <div class="section pt-8" id="alles">
      <h2 class="text-center" style="font-size: 22pt">Ons laatste nieuws</h2>

      <div class="nieuws mt-8">
        <v-card class="elevation-2" v-for="(n, index) in nieuws" :key="index">
          <v-img rounded :src="n.header_image"></v-img>
          <div class="pa-8">
            <p>{{ getDate(n.timestamp) }}</p>
            <p class="my-2" style="font-size: 18pt; font-weight: 600">
              {{ n.title }}
            </p>
            <p class="mt-4">
              {{ n.subtitle }}
            </p>
            <v-btn
              class="mt-6"
              variant="outlined"
              @click="$router.push(`/nieuws/${n.uid}`)"
              >Lees verder
              <v-icon class="ml-2" size="16">mdi-arrow-right</v-icon></v-btn
            >
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";
import { useMeta } from "vue-meta";

export default {
  setup() {
    useMeta({
      title: "Nieuws",
    });
  },
  computed: {
    ...mapState({
      nieuws: (state) => state.nieuws,
    }),
  },
  methods: {
    getDate(d) {
      let i = DateTime.fromSeconds(d);
      return i.toLocaleString(DateTime.DATETIME_FULL);
    },
  },
};
</script>

<style lang="scss" scoped>
.nieuws {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  // grid-template-rows: repeat(3, minmax(0, 1fr));
  grit-template-rows: auto;
  grid-auto-flow: column;
  gap: 50px;
}

@media screen and (max-width: 700px) {
  .nieuws {
    display: flex;
    flex-direction: column;
  }
}
</style>
