<template>
  <div>
    <div class="section historie" id="historie">
      <div class="intro">
        <div class="textleft left d-flex flex-column justify-center pr-10">
          <p class="text-primary mb-2"><b>Historie</b></p>
          <h1 style="font-size: 30pt; line-height: 35pt">Hoe het begon</h1>
          <p style="font-size: 14pt" class="mt-6">
            Stichting We Share4Kids is voortgekomen uit de wens van enkele
            toegewijde burgers om iets terug te geven aan de samenleving. Het is
            voor gezinnen waarin een kind lijdt of heeft geleden aan een
            ernstige ziekte moeilijk om de overgang te maken van de
            behandelingsfase terug naar het 'normale' leven. In deze periode
            ontbreekt vaak de vertrouwde gezinsdynamiek en zijn de
            energiereserves uitgeput, terwijl nieuwe rituelen en een nieuwe
            mindset nog moeten worden gevonden. <br /><br />
            Wij geloven in het principe van wederkerigheid: door te delen en
            elkaar te helpen, kunnen we samen de lasten verlichten en de weg
            naar genezing en herstel effenen. <br /><br />Daarom streven we met
            WeShare4Kids ernaar om een gratis ‘gezinsverblijf’ aan te bieden,
            gericht op het ondersteunen van gezinnen na de behandeling van
            ernstige ziekten bij kinderen. <br /><br />Met uw steun kunnen we
            een positief verschil maken in het leven van kinderen en gezinnen
            die dit het meest nodig hebben. Laten we daarom samen ons ‘tweede
            huis’ delen en zo een beetje bijdragen aan een betere toekomst voor
            alle kinderen.
          </p>
        </div>
        <div class="right">
          <v-img
            :eager="true"
            :src="require('@/assets/stock/spelen.jpg')"
            style="border-radius: 10px"
          ></v-img>
        </div>
      </div>
    </div>

    <div class="section" id="missie">
      <div class="intro-reverse">
        <div class="right">
          <v-img
            :eager="true"
            :src="require('@/assets/stock/knuffel.jpg')"
            style="border-radius: 10px"
          ></v-img>
        </div>
        <div class="textright left d-flex flex-column justify-center">
          <p class="text-primary mb-2"><b>Missie</b></p>
          <h1 style="font-size: 30pt; line-height: 35pt">
            Een nieuwe start voor het hele gezin
          </h1>
          <p style="font-size: 14pt" class="mt-6">
            Juist omdat in deze situaties niet alleen het kind, maar het hele
            gezin een zware tijd heeft gehad, wil de stichting graag proberen
            voor deze gezinnen een ontspannen en positieve overgang te
            faciliteren en heeft daarbij de volgende missie gedefinieerd:
            <br /><br /><i
              ><b
                >‘Wij bieden gezinnen waar een kind ernstig ziek is (geweest)
                een bijdrage aan een nieuwe start, om een moeilijke periode
                samen te kunnen afsluiten, samen weer op te laden en het begin
                van een nieuwe toekomst te kunnen vieren’.</b
              ></i
            >
            <br /><br />
            De stichting ziet 'kanker bij kinderen' als een eerste indicatie om
            aandacht aan te geven. Het is de bedoeling om in een later stadium
            geleidelijk de range van indicaties verder te verbreden.
          </p>
        </div>
      </div>
    </div>

    <div class="section" id="visie">
      <div class="intro">
        <div class="textleft left d-flex flex-column justify-center pr-10">
          <p class="text-primary mb-2"><b>Visie</b></p>
          <h1 style="font-size: 30pt; line-height: 35pt">
            Nieuwe gezinsdynamiek
          </h1>
          <p style="font-size: 14pt" class="mt-6">
            Stichting WeShare4Kids is een initiatief van enkele geëngageerde
            burgers die graag iets terug willen doen voor de samenleving. Voor
            gezinnen, waarin een kind lijdt of geleden heeft aan een ernstige
            ziekte is de overgang van de behandelperiode weer naar het ‘gewone’
            leven vaak zwaar en moeilijk. De vertrouwde gezinsdynamiek is er in
            veel gevallen niet meer en de energie is uitgeput terwijl nieuwe
            rituelen en een nieuwe mindset nog moeten worden gevonden.
          </p>
        </div>
        <div class="right">
          <v-img
            :eager="true"
            :src="require('@/assets/stock/gezin.jpg')"
            style="border-radius: 10px"
          ></v-img>
        </div>
      </div>
    </div>
    <div class="section text-center" id="werkwijze">
      <p class="text-primary"><b>Werkwijze</b></p>
      <h1>Hoe het werkt</h1>
      <div class="werkwijze my-6 text-center">
        <v-card class="pa-8">
          <v-avatar size="100" class="mb-4">
            <v-img
              src="@/assets/sun.svg"
              class="d-flex align-center justify-center"
            >
              <b style="font-size: 16pt">1</b></v-img
            >
          </v-avatar>
          <h3>Aanbod</h3>
          <p style="font-size: 14pt" class="mt-2">
            Veel mensen in Nederland (en daarbuiten) hebben een tweede
            accommodatie waar ze zelf graag bijtanken of vakanties doorbrengen,
            zoals een huisje, caravan, camper, vakantiehuis, of boot. Eigenaren
            die deel uitmaken van het WeShare4Kids-initiatief willen hun
            verblijf beschikbaar stellen aan gezinnen met een ernstig ziek kind,
            zelfs gratis. Ze dragen de kosten zelf en ervaren voldoening door
            hun onbaatzuchtige bijdrage aan de samenleving.
          </p>
        </v-card>

        <v-card class="pa-8">
          <v-avatar size="100" class="mb-4">
            <v-img
              src="@/assets/sun.svg"
              class="d-flex align-center justify-center"
            >
              <b style="font-size: 16pt">2</b></v-img
            >
          </v-avatar>
          <h3>Vraag</h3>
          <p style="font-size: 14pt" class="mt-2">
            Geïndiceerde gezinnen kunnen een aanvraag doen om een lang weekend,
            een week of wellicht nog langer in één van de geregistreerde
            accommodaties te verblijven. Van dit verblijf kan het gezin dus
            kosteloos gebruik maken, krijgt het de mogelijkheid weer op te laden
            en een happy herstart te beleven na een zware periode van herstel.
          </p>
        </v-card>

        <v-card class="pa-8">
          <v-avatar size="100" class="mb-4">
            <v-img
              src="@/assets/sun.svg"
              class="d-flex align-center justify-center"
            >
              <b style="font-size: 16pt">3</b></v-img
            >
          </v-avatar>
          <h3>Matching</h3>
          <p style="font-size: 14pt" class="mt-2">
            Op de digitale marktplaats van WeShare4Kids kunnen aanbieders hun
            verblijf toevoegen met beschikbaarheidsdata, capaciteit, en
            bijzonderheden. Gezinnen die geïnteresseerd zijn, doorlopen een
            selectieproces via een gedigitaliseerde vragenlijst op de
            ‘Matching-website’. Bij goedkeuring kunnen ze een accommodatie
            kiezen en rechtstreeks contact opnemen met de aanbieder, die de
            aanvraag moet goedkeuren. Aanvrager en aanbieder communiceren direct
            over locatie, bereikbaarheid, en faciliteiten.
          </p>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";

export default {
  setup() {
    useMeta({
      title: "Over ons",
    });
  },
};
</script>

<style lang="scss" scoped>
.intro {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 25px;
  align-items: center;

  .image {
    border: 5px solid #fc9c4c;
  }
}
.textleft {
  padding: 0 10% 0 0;
}
.textright {
  padding: 0 0 0 10%;
  padding-left: 40px;
}

.intro-reverse {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 25px;
  align-items: center;

  .image {
    border: 5px solid #fc9c4c;
  }
}

.werkwijze {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.historie {
  margin-top: 5vh;
}

@media screen and (max-width: 700px) {
  .historie {
    margin-top: 10px;
  }
  .intro {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    align-items: center;

    .image {
      border: 5px solid #fc9c4c;
    }
  }
  .textleft {
    padding: 0;
  }
  .textright {
    padding: 0;
  }
  .intro-reverse {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    align-items: center;

    .right {
      order: 1;
    }

    .image {
      border: 5px solid #fc9c4c;
    }
  }

  .werkwijze {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
</style>
