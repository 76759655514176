<template>
  <div style="padding: 5%; max-width: 1000px">
    <div class="d-flex justify-space-between align-center">
      <div>
        <h1 style="font-size: 30pt; font-weight: 600">
          Nieuwsbrief inschrijvingen
        </h1>
        <p class="mb-10">Bekijk alle inschrijvingen</p>
      </div>
    </div>

    <v-data-table-virtual
      :headers="headers"
      :items="nieuwsbrieven"
      item-value="email"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          @click="$store.dispatch('removeNieuwsbrief', item.uid)"
          variant="text"
          size="small"
          icon="mdi-delete"
          class="mr-2"
        ></v-btn>
      </template>
    </v-data-table-virtual>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      nieuwsbrieven: (state) => state.nieuwsbrieven,
    }),
  },
  data() {
    return {
      headers: [
        { title: "Email", align: "start", key: "email" },
        {
          titel: "Acties",
          align: "end",
          key: "actions",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("getNieuwsbrieven");
  },
};
</script>
