<template>
  <div>
    <v-divider thickness="2" color="secondary"></v-divider>
    <NieuwsBrief />
    <v-divider thickness="2" color="secondary"></v-divider>
    <div class="footer">
      <div>
        <v-img
          :style="{ width: this.$vuetify.display.smAndUp ? '100%' : '100px' }"
          src="@/assets/logo.svg"
        ></v-img>
      </div>
      <div>
        <h3>WeShare4Kids</h3>
        <p style="font-size: 10pt" class="pr-2 mb-2">
          Stichting WeShare4Kids richt zich op kinderen met een ernstige ziekte
          en op het weer bij elkaar brengen van het gezin in een ontspannen
          sfeer, door een gratis verblijf in een andere (vakantie-) omgeving aan
          te bieden. Uiteraard pas als dit medisch gesproken verantwoord is.
        </p>
        <p style="font-size: 10pt">RSIN: 866405239</p>
        <p style="font-size: 10pt">Kamer van Koophandel: 93448953</p>
        <p style="font-size: 10pt">IBAN: NL59 RABO 0387 9982 17</p>
      </div>
      <div class="top" style="width: 100%" @click="scrollTop()">
        <v-btn class="mx-auto" color="primary" icon="mdi-arrow-up"></v-btn>
      </div>
      <div class="links" style="text-decoration: underline; cursor: pointer">
        <h3 @click="$router.push('/overons')">Over ons</h3>
        <p @click="$router.push('/overons#historie')" class="mb-2">Historie</p>
        <p @click="$router.push('/overons#missie')" class="mb-2">Missie</p>
        <p @click="$router.push('/overons#visie')" class="mb-2">Visie</p>
        <p @click="$router.push('/overons#werkwijze')" class="mb-2">
          Werkwijze
        </p>
      </div>
      <div class="links" style="text-decoration: underline; cursor: pointer">
        <h3 @click="$router.push('/organisatie')">Organisatie</h3>
        <p @click="$router.push('/organisatie#team')" class="mb-2">Team</p>
        <p @click="$router.push('/organisatie#sponsoren')" class="mb-2">
          Sponsoren
        </p>
      </div>
      <div class="links" style="text-decoration: underline; cursor: pointer">
        <h3 @click="$router.push('/nieuws')">Nieuws</h3>
        <p @click="$router.push('/nieuws')" class="mb-2">
          Bekijk alle nieuwsartikelen
        </p>
      </div>
      <div class="links" style="text-decoration: underline; cursor: pointer">
        <h3 @click="$router.push('/contact')">Contact</h3>
        <p @click="$router.push('/steunons')" class="mb-2">Steun ons</p>
        <p @click="$router.push('/contact')" class="mb-2">Contactformulier</p>
      </div>
    </div>
  </div>
</template>

<script>
import NieuwsBrief from "@/components/NieuwsBrief.vue";
export default {
  components: {
    NieuwsBrief,
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  max-width: 1200px;
  width: 90vw;
  margin: auto;
  padding: 10vh 0;
  display: grid;
  grid-template-columns: 150px 2fr 1fr 1fr 1fr 1fr;
  gap: 20px;

  h3 {
    margin-bottom: 10px;
  }
}

.top {
  display: none;
}

@media screen and (max-width: 700px) {
  .footer {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .top {
    display: block;
  }
  .links {
    display: none;
  }
}
</style>
