<template>
  <div>
    <div class="section text-center">
      <p class="mb-0 pb-0 text-primary"><b>Contact</b></p>
      <h1 class="mb-0 mt-2 mx-auto" style="max-width: 700px; font-size: 30pt">
        Neem contact met ons op
      </h1>
      <!-- <p class="mx-auto" style="font-size: 14pt; max-width: 500px">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
        gravida tempus viverra. Sed non pretium nibh, in tristique diam.
      </p> -->
    </div>

    <div class="formulier section pt-0" id="formulier">
      <v-card class="form">
        <v-img
          cover
          v-if="this.$vuetify.display.smAndUp"
          height="100%"
          src="https://images.pexels.com/photos/220455/pexels-photo-220455.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        ></v-img>
        <div class="pa-10">
          <h2>Contactformulier</h2>
          <p class="mb-4" style="font-size: 10pt">*verplicht</p>
          <div class="right">
            <v-text-field
              label="Uw naam *"
              variant="solo-filled"
              v-model="message.name"
              flat
            ></v-text-field>
            <v-text-field
              label="Uw email adres *"
              variant="solo-filled"
              v-model="message.email"
              flat
            ></v-text-field>
            <v-select
              v-model="message.subject"
              variant="solo-filled"
              label="Onderwerp *"
              flat
              :items="items"
            ></v-select>
            <v-text-field
              label="Telefoonnummer"
              variant="solo-filled"
              v-model="message.phone"
              flat
            ></v-text-field>

            <v-textarea
              hide-details
              v-model="message.message"
              label="Uw bericht *"
              style="grid-column-start: 1; grid-column-end: 3"
            ></v-textarea>
            <v-btn
              style="grid-column-start: 1; grid-column-end: 3"
              :color="sent ? 'green' : 'primary'"
              block
              @click="sendMail"
              :readonly="sent"
              :disabled="
                !sent &&
                (!message.message ||
                  !message.email ||
                  !message.name ||
                  !message.subject)
              "
              :loading="sending"
              height="40"
              ><v-icon v-if="sent" class="mr-1" size="small" color="white"
                >mdi-check-circle</v-icon
              >{{ sent ? "Verzonden!" : "Verzenden" }}</v-btn
            >
          </div>
        </div>
      </v-card>
    </div>

    <div class="d-flex justify-center" style="margin-bottom: 100px">
      <v-card class="formulier pa-8 d-flex flex-wrap align-center">
        <v-img
          height="100"
          width="100"
          :src="require('@/assets/logo.svg')"
        ></v-img>
        <div>
          <h2>Over de stichting</h2>
          <p>
            Adres: Van der Does de Willeboissingel 8, 5211 CA ’s-Hertogenbosch
          </p>
          <p class="mb-0 pb-0">
            E-mail:
            <A HREF="mailto:info@weshare4kids.com">info@weshare4kids.com</A>
          </p>
        </div>
      </v-card>
    </div>

    <!-- <div
      class="section text-center"
      id="faq"
      style="max-width: 1000px; width: 70vw"
    >
      <p class="mb-0 pb-0 text-primary"><b>FAQ</b></p>
      <h1 class="mb-4 mt-2 mx-auto" style="max-width: 700px; font-size: 30pt">
        Meest gestelde vragen
      </h1>
      <p class="mx-auto" style="font-size: 14pt; max-width: 500px">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
        gravida tempus viverra. Sed non pretium nibh, in tristique diam.
      </p>
      <div style="margin-top: 50px" class="text-start">
        <p class="mb-2">
          <u
            >Ik (ons gezin) wil graag gebruik maken van een vakantieverblijf
            <v-icon size="14">mdi-arrow-down</v-icon></u
          >
        </p>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title style="font-size: 12pt; font-weight: 600">
              Hoe lang duurt het voordat mijn aanvraag is goedgekeurd?
            </v-expansion-panel-title>
            <v-divider></v-divider>
            <v-expansion-panel-text class="text-start" style="font-size: 11pt">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
              ratione debitis quis est labore voluptatibus! Eaque cupiditate
              minima
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title style="font-size: 12pt; font-weight: 600">
              Ik heb zelf een huis, hoe kan ik deze beschikbaar stellen?
            </v-expansion-panel-title>
            <v-divider></v-divider>
            <v-expansion-panel-text class="text-start" style="font-size: 11pt">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
              ratione debitis quis est labore voluptatibus! Eaque cupiditate
              minima
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div style="margin-top: 50px" class="text-start">
        <p class="mb-2">
          <u
            >Ik wil mijn vakantieverblijf graag beschikbaar stellen

            <v-icon size="14">mdi-arrow-down</v-icon></u
          >
        </p>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title style="font-size: 12pt; font-weight: 600">
              Hoe lang duurt het voordat mijn aanvraag is goedgekeurd?
            </v-expansion-panel-title>
            <v-divider></v-divider>
            <v-expansion-panel-text class="text-start" style="font-size: 11pt">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
              ratione debitis quis est labore voluptatibus! Eaque cupiditate
              minima
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title style="font-size: 12pt; font-weight: 600">
              Ik heb zelf een huis, hoe kan ik deze beschikbaar stellen?
            </v-expansion-panel-title>
            <v-divider></v-divider>
            <v-expansion-panel-text class="text-start" style="font-size: 11pt">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
              ratione debitis quis est labore voluptatibus! Eaque cupiditate
              minima
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div> -->
  </div>
</template>

<script>
import { collection, addDoc } from "firebase/firestore";
import { db } from "@/firebase";
import { useMeta } from "vue-meta";

export default {
  setup() {
    useMeta({
      title: "Contact",
    });
  },
  data() {
    return {
      message: {
        name: "",
        subject: "",
        email: "",
        phone: "",
        message: "",
      },
      sending: false,
      sent: false,
      items: [
        "Ik (ons gezin) wil gebruik maken van een vakantieverblijf",
        "Ik wil mijn vakantieverblijf beschikbaar stellen",
        "Ik (ons bedrijf) wil sponsor worden",
        "Ik wil donateur worden",
        "Ik wil vrijwilliger worden",
        "Anders",
      ],
    };
  },
  methods: {
    async sendMail() {
      this.sending = true;
      await addDoc(collection(db, "mail"), {
        to: ["info@weshare4kids.com"],
        bcc: ["maxvankaathoven@gmail.com"],
        replyTo: this.message.email,
        message: {
          subject: `Nieuw contactformulier: ${this.message.subject}`,
          html: `<p>Onderwerp: ${this.message.subject}</p><p>Naam: ${this.message.name}</p><p>Email: ${this.message.email}</p><p>Telefoon: ${this.message.phone}</p><p>Bericht: ${this.message.message}</p>`,
        },
      });
      this.message = {
        name: "",
        subject: "",
        email: "",
        phone: "",
        message: "",
      };
      this.sending = false;
      this.sent = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.formulier {
  max-width: 1000px;
  width: 70vw;
}
.form {
  display: grid;
  grid-template-columns: 50% auto;

  .right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 3fr 1fr;
    column-gap: 30px;
    row-gap: 0px;
  }
}

@media screen and (max-width: 700px) {
  .form {
    grid-template-columns: 1fr;

    .right {
      gap: 0px;
      display: flex;
      flex-direction: column;
    }
  }
  .formulier {
    width: 90%;
  }
}
</style>
